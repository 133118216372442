var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isEventWrapper)?_c('div',{staticClass:"button-wrapper"},[_c('button',{staticClass:"add-to-cart-button",attrs:{"theme":"secondary","size":"small"},on:{"click":function($event){return _vm.addItemToCart($event, {
        element: _vm.element,
        cartData: _vm.cartData,
        productData: _vm.productData,
        accountsData: _vm.accountsData
      })}}},[_vm._v(" "+_vm._s(_vm.customText ? _vm.customText : _vm.functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart')+" ")])]):_c('div',[_c('fdk-cart',{ref:"cart",scopedSlots:_vm._u([{key:"default",fn:function(cartData){return [_c('div',{staticClass:"button-wrapper"},[_c('button',{staticClass:"add-to-cart-button",attrs:{"theme":"secondary","size":"small"},on:{"click":function($event){return _vm.addItemToCart($event, {
            element: _vm.element,
            cartData,
            productData: _vm.productData,
            accountsData: _vm.accountsData
          })}}},[_vm._v(" "+_vm._s(_vm.customText ? _vm.customText : _vm.functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart')+" ")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }