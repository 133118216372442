<template>
  <div>
    <ApiWrapper id="bought-together" />
  </div>
</template>

<script>
import ApiWrapper from './hoc/ApiWrapper.vue';
export default {
  name: 'by-brand', // why all components have same name?
  components: {
    ApiWrapper
  }
};
</script>