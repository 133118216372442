<template>
  <div
    v-if="discount != ''"
    class="offer-vertical"
    :class="['offer-' + discountPosition + '-vertical']"
  >
    <div class="svg-holder">

      <svg
        width="100%"
        height="100%"
        top="0"
        left="0"
        viewBox="0 0 26 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="62"
          width="62"
          height="26"
          rx="4"
          transform="rotate(-90 0 62)"
          fill="#ffffff00"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.22041e-07 61.9429L12.3501 50L0 48L5.22041e-07 61.9429ZM25.941 62L26 62L26 48L12.3501 50L25.941 62Z"
          fill="#2F80ED"
          class="background_c"
        />
        <rect
          width="50"
          height="26"
          transform="translate(26) rotate(90)"
          fill="#2F80ED"
          class="background_c"
        />
      </svg>
    </div>
    <div class="offer-text-container">
      <div class="offer-text">{{ discount }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LabelInvertedVertical",
  props: {
    discountPosition: {
      type: String,
      default: "",
    },
    discount: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>

.offer-vertical {
  height: 90px;
  width: 36px;
  position: absolute;
  overflow: hidden;
}

.svg-holder {
  height: 90px;
}

.offer-text {
  display: flex;
  width: 70px;
  justify-content: center;
}

.offer-text-container {
  font-size: calc(0px + min(15px, var(--discountSize)));
  font-weight: 500;
  color: var(--discountColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotate(90deg) translate(calc(4px), calc(27px));
  margin-top: -75px;
  width: 70px;
  height: 35px;
  margin-left: 10px;
}

.offer-top-left-inverted-vertical {
  top: -2px;
  left: calc(4px + max(4px, var(--cardBorderRadius)));
}
.offer-top-right-inverted-vertical {
  top: -2px;
  right: calc(4px + max(4px, var(--cardBorderRadius)));
}
.background_c {
  fill: var(--discountbgColor);
}
</style>
