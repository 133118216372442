var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recommendation-container",attrs:{"id":"recommendation-container"}},[(Object.keys(_vm.config).length && _vm.product.items.length)?_c('div',{staticClass:"product-container",style:(_vm.cssProps)},[_c('IconAndTitle',{attrs:{"iconUrl":_vm.config.widget_settings.logo ? _vm.config.widget_settings.logo : '',"brand":_vm.brand,"gender":_vm.gender,"category":_vm.category,"title":_vm.config.widget_settings.title,"subTitle":_vm.config.widget_settings.sub_title}}),_c('div',{staticClass:"flex grid-gap-15 scroll-container"},[_c('div',{staticClass:"rec_left",class:!_vm.scrollView.left
            ? 'visibility_hidden'
            : _vm.config.widget_settings.scroll_arrows
              ? 'white_bg_arrow'
              : 'transparent_bg_left_arrow',attrs:{"id":"left-button"},on:{"click":_vm.swipeLeft}},[_c('img',{staticClass:"arrow left-arrow",attrs:{"alt":"arrow_left","src":require("../assets/images/arrow.svg")}})]),_c('div',{ref:"content",staticClass:"flex grid-gap-15 rec_center",attrs:{"id":"content"}},_vm._l((_vm.product.items.slice(0, _vm.config.widget_settings.max_products)),function(n,index){return _c('div',{key:n,staticClass:"product",class:_vm.config.product_card_layout.style,attrs:{"id":`product-list-item-${index}-${_vm.recomm_slug}-${_vm.configId}`}},[(_vm.isEventWrapper)?_c('div',{class:_vm.showVariantSelectScreen ? 'flex-grow' : 'flex-grow'},[_c('ProductData',{attrs:{"config":_vm.config,"functionalConfigAddToCart":_vm.functionalConfigAddToCart,"n":n,"index":index,"review_map":_vm.review_map,"isMounted":_vm.isMounted,"isEventWrapper":_vm.isEventWrapper,"follow":_vm.follow,"utm":_vm.utm,"recomm_slug":_vm.recomm_slug,"configId":_vm.configId},on:{"showVariantSelectScreen":_vm.showVariantSelectScreenFunction}})],1):_c('div',{staticClass:"h-full",attrs:{"id":"first-container"}},[_c('fdk-product-card',{ref:"productCard",refInFor:true,staticClass:"h-full",attrs:{"id":"second-container"},scopedSlots:_vm._u([{key:"default",fn:function(productData){return [_c('fdk-accounts',{staticClass:"h-full",scopedSlots:_vm._u([{key:"default",fn:function(accountsData){return [_c('ProductData',{attrs:{"config":_vm.config,"functionalConfigAddToCart":_vm.functionalConfigAddToCart,"n":n,"review_map":_vm.review_map,"isMounted":_vm.isMounted,"isEventWrapper":_vm.isEventWrapper,"follow":_vm.follow,"utm":_vm.utm,"productData":productData,"accountsData":accountsData,"recomm_slug":_vm.recomm_slug,"configId":_vm.configId,"index":index}})]}}],null,true)})]}}],null,true)})],1)])}),0),_c('div',{staticClass:"rec_right",class:!_vm.scrollView.right
            ? 'visibility_hidden'
            : _vm.config.widget_settings.scroll_arrows
              ? 'white_bg_arrow'
              : 'transparent_bg_right_arrow',attrs:{"id":"right-button"},on:{"click":_vm.swipeRight}},[_c('img',{staticClass:"arrow right-arrow",attrs:{"alt":"arrow_right","src":require("../assets/images/arrow.svg")}})])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }