<template>
  <ApiWrapper id="recently-launched" />
</template>

<script>
import ApiWrapper from './hoc/ApiWrapper.vue';
export default {
  name: 'by-brand',
  components: {
    ApiWrapper
  }
};
</script>
