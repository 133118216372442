<template>
  <div>
    <div
      class="price"
      v-if="price && isPriceActive && priceLayout == 'layout1'"
    >
      <span
        class="selling-price"
        v-if="price.effective.min == price.effective.max"
        >{{ price.effective.currency_symbol
        }}{{ numberWithCommas(price.effective.max) }} </span
      ><span
        class="selling-price"
        v-if="price.effective.min != price.effective.max"
        >{{ price.effective.currency_symbol
        }}{{ numberWithCommas(price.effective.min) }} -
        {{ price.effective.currency_symbol
        }}{{ numberWithCommas(price.effective.max) }} </span
      ><span
        class="cut-price"
        v-if="
          secondaryPriceActive &&
          price.marked != price.effective &&
          price.marked.min == price.marked.max &&
          price.marked.max != price.effective.max &&
          price.marked.max != price.effective.min
        "
        >{{ price.marked.currency_symbol
        }}{{ numberWithCommas(price.marked.max) }}</span
      ><span
        class="cut-price"
        v-if="
          secondaryPriceActive &&
          price.marked != price.effective &&
          price.marked.min != price.marked.max &&
          (price.marked.max != price.effective.max ||
            price.marked.min != price.effective.min)
        "
        >{{ price.marked.currency_symbol
        }}{{ numberWithCommas(price.marked.min) }} -
        {{ price.marked.currency_symbol
        }}{{ numberWithCommas(price.marked.max) }}</span
      >
    </div>
    <div
      class="price"
      v-if="price && isPriceActive && priceLayout == 'layout2'"
    >
      <span
        class="cut-price"
        v-if="
          secondaryPriceActive &&
          price.marked != price.effective &&
          price.marked.min == price.marked.max
        "
        >{{ price.marked.currency_symbol
        }}{{ numberWithCommas(price.marked.max) }}</span
      ><span
        class="cut-price"
        v-if="
          price.marked != price.effective &&
          price.marked.min != price.marked.max
        "
        >{{ price.marked.currency_symbol
        }}{{ numberWithCommas(price.marked.min) }} -
        {{ price.marked.currency_symbol
        }}{{ numberWithCommas(price.marked.max) }}</span
      ><span class="selling-price" v-if="price.marked.min == price.marked.max">
        {{ price.effective.currency_symbol
        }}{{ numberWithCommas(price.effective.max) }} </span
      ><span class="selling-price" v-if="price.marked.min != price.marked.max">
        {{ price.effective.currency_symbol
        }}{{ numberWithCommas(price.effective.min) }} -
        {{ price.effective.currency_symbol
        }}{{ numberWithCommas(price.effective.max) }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "PriceBox",
  props: {
    price: {
      type: Object,
      default: null,
    },
    isPriceActive: {
      type: Boolean,
      default: false,
    },
    priceLayout: {
      type: String,
      default: "",
    },
    productName: {
      type: String,
      default: "",
    },
    secondaryPriceActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted(){
  }
};
</script>

<style scoped>
.price {
  font-size: var(--pricefontSize);
  text-align: var(--priceAlignment);
  margin-top: 5px;
  font-weight: var(--priceFontWeight);
}
.price .cut-price {
  text-decoration: line-through;
}
.selling-price {
  color: var(--primaryPriceColor);
  margin-right: 3px;
}

.cut-price {
  font-weight: 400;
  color: var(--secondaryPriceColor);
}
</style>
