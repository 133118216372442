<template>
    <div>
        <ApiWrapper id="personalised-products" />
    </div>
</template>
  
<script>
import ApiWrapper from "./hoc/ApiWrapper.vue";
export default {
    name: "personalised-products",
    components: {
        ApiWrapper,
    },
};
</script>