<template>
  <div class="image-offer">
    <div class="image-box">
      <img alt="icon" :src="iconUrl" :class="{ 'image-height': !productImageScale }" />
      <div
        class="offer"
        v-if="
          active &&
          discount != '' &&
          cardLayoutStyle == 'card-layout-1' &&
          discountPosition == 'bottom-left'
        "
        :class="['offer-card-1-' + discountPosition]"
      >
        <div class="svg-holder">
          <svg
            width="100%"
            height="100%"
            top="0"
            left="0"
            viewBox="0 0 56 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="56" height="20" rx="4" fill="#ffffff00" />
            <rect width="46.1" height="20" fill="#2F80ED" class="background_c" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M55.9524 0L46 9.5V0H55.9524ZM56 19.9545V20H46V9.5L56 19.9545Z"
              fill="#2F80ED"
              class="background_c"
            />
          </svg>
        </div>
        <div class="offer-text-container">
          <div class="offer-text">{{ discount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImageAndOffer',
  props: {
    cardLayoutStyle: {
      type: String,
      default: ''
    },
    iconUrl: {
      type: String,
      default: ''
    },
    productImageScale: {
      type: Boolean,
      default: false
    },
    discountPosition: {
      type: String,
      default: ''
    },
    discount: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
  mounted() {
  }
};
</script>

<style scoped>
.image-offer {
  position: relative;
  display: flex;
}
.card-layout-1 {
  display: flex;
  flex-direction: column;
}
.image-box {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
  overflow: hidden;
  border-radius: var(--cardBorderRadius) var(--cardBorderRadius) 0 0;
}
.card-layout-1 .image-box {
  background-color: var(--cardBackgroundColor);
}
.image-box .image-height {
  height: var(--imageHeight) !important;
  object-fit: contain;
  max-width: calc(var(--imageHeight) * 0.819) !important;
}
.image-box img:not(.image-height) {
  height: var(--imageHeight) !important;
  object-fit: fill;
  width: 100%;
  overflow: hidden;
}
.offer {
  height: 30px;
  width: 90px;
  position: absolute;
  overflow: hidden;
}

.offer-text-container {
  margin-top: -36px;
}

.offer-text {
  font-size: calc(0px + min(16px, var(--discountSize)));
  font-weight: 500;
  color: var(--discountColor);
  overflow: hidden;
  display: flex;
  height: 30px;
  width: 77px;
  justify-content: center;
  align-items: center;
}

.offer-card-1-bottom-left {
  bottom: 0;
  left: calc(0px - var(--cardPadding));
}

.background_c {
  fill: var(--discountbgColor);
}
</style>
