<template>
  <div>
    <div v-if="isEventWrapper">
      <div ref="productCard">
        <div>
          <div
            :class="
              isPreviewScreen ? 'recommendation-container-eventWrapper' : 'recommendation-container'
            "
          >
            <div class="item-container">
              <div class="item-image-container">
                <img class="item-image" :src="element?.medias?.[0]?.url" />
              </div>
              <div class="item-description">
                <div class="product-title" v-if="config?.product_title?.active">
                  {{ capitalizeFirstLetter(element?.brand?.name) || 'Brand Name' }}
                </div>
                <div class="product-name">{{ element?.name }}</div>
                <div class="seller-info">
                  Sold By:
                  {{ element?.attributes?.['marketer-name'] || 'Seller Name' }}
                </div>

                <div class="add-to-cart-container">
                  <div class="size-button-container">
                    <CustomDropdownCrossSell
                      v-model="selectedSize"
                      placeholder="Size : "
                      :items="availableSize"
                      v-if="availableSize?.length > 0"
                    />
                    <CustomDropdownCrossSell
                      v-model="selectedQuantity"
                      placeholder="Qty : "
                      :items="availableQuantity"
                    />
                  </div>
                  <div class="cart-button-container">
                    <div v-if="config.wishlist.active">
                      <div
                        @click="wishit($event, element, productData, accountsData)"
                        class="wish-list-icon-container"
                        v-if="isEventWrapper ? isWishlisted : follow[element?.slug]"
                      >
                        <WishListHeartIcon :selectedColor="config.wishlist.color" />
                      </div>
                      <div
                        @click="wishit($event, element, productData, accountsData)"
                        class="wish-list-icon-container"
                        v-else
                      >
                        <WishListHeartIcon :selectedColor="config.wishlist.defaultColor" />
                      </div>
                    </div>
                    <div
                      ref="cart"
                      id="cart-div"
                      v-if="functionalConfigAddToCart?.add_to_cart?.active"
                    >
                      <button
                        theme="secondary"
                        class="add-to-cart-button"
                        size="small"
                        @click="addItemToCart({ element, cartData, productData, accountsData })"
                      >
                        {{
                          customText
                            ? customText
                            : functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart'
                        }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="price-container" v-if="config?.price?.layout === 'layout1'">
                  <div class="price-sub-container" v-show="config?.price?.active">
                    <div
                      class="discounted-price"
                      v-show="
                        element?.discount &&
                        element?.discount !== '' &&
                        config.price.secondary_price_active
                      "
                    >
                      {{
                        `${element?.price?.marked?.currency_symbol}${element?.price?.marked?.max}`
                      }}
                    </div>
                    <div class="actual-price">
                      {{
                        `${element?.price?.effective?.currency_symbol}${element?.price?.effective?.max}`
                      }}
                    </div>
                  </div>
                  <div
                    v-show="
                      config?.discount?.active && element?.discount && element?.discount !== ''
                    "
                    class="offer-applied-container"
                  >
                    <div class="offer-applied-text">
                      {{ element?.discount }}
                    </div>
                    <ExclamationMarkIconSvg
                      alt="exclamation-mark-icon"
                      class="exclamation-mark-icon"
                      :color="config.discount.text_color"
                    />
                  </div>
                </div>
                <div class="price-container" v-if="config.price.layout === 'layout2'">
                  <div class="price-sub-container" v-show="config?.price?.active">
                    <div class="actual-price">
                      {{
                        `${element?.price?.effective?.currency_symbol}${element?.price?.effective?.max}`
                      }}
                    </div>
                    <div
                      class="discounted-price"
                      v-show="
                        element?.discount &&
                        element?.discount !== '' &&
                        config.price.secondary_price_active
                      "
                    >
                      {{
                        `${element?.price?.marked?.currency_symbol}${element?.price?.marked?.max}`
                      }}
                    </div>
                  </div>
                  <div
                    v-show="element?.discount && element?.discount !== ''"
                    class="offer-applied-container"
                  >
                    <div class="offer-applied-text">
                      {{ element?.discount }}
                    </div>
                    <img
                      alt="exclamation-mark-icon"
                      class="exclamation-mark-icon"
                      src="../../src/assets/images/ExclamationMarkIcon.png"
                    />
                  </div>
                </div>
                <div
                  class="expected-delivery-container"
                  v-show="config?.product_highlights?.active || failedAddingToCart"
                >
                  <div class="expected-delivery-title">
                    {{ failedAddingToCart ? failedAddingToCartMessage : 'Expected Delivery:' }}
                  </div>
                  <!-- <div class="expected-delivery-text">----</div> -->
                </div>
                <div class="add-to-cart-container-mobile">
                  <div class="size-button-container">
                    <CustomDropdownCrossSell
                      v-if="availableSize?.length > 0"
                      v-model="selectedSize"
                      placeholder="Size : "
                      :items="availableSize"
                    />
                    <CustomDropdownCrossSell
                      v-model="selectedQuantity"
                      placeholder="Qty : "
                      :items="availableQuantity"
                    />
                  </div>
                  <div class="cart-button-container">
                    <div ref="cart" v-if="functionalConfigAddToCart?.add_to_cart?.active">
                      <button
                        class="add-to-cart-button"
                        theme="secondary"
                        size="small"
                        @click="
                          addItemToCart({ element, cartData, productData, accountsData, index })
                        "
                      >
                        {{
                          customText
                            ? customText
                            : functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart'
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <fdk-product-card v-slot="productData" ref="productCard">
        <fdk-accounts v-slot="accountsData">
          <div class="recommendation-container">
            <div class="item-container">
              <div class="item-image-container">
                <a
                  :href="`/product/${element.slug}?${utm.name}_source=${utm.source}&${utm.name}_medium=${utm.medium}&item_code=${element.item_code}`"
                  target="_blank"
                >
                  <img class="item-image" :src="element?.medias?.[0]?.url" />
                </a>
              </div>
              <div class="item-description">
                <div class="product-title" v-if="config?.product_title?.active">
                  {{ capitalizeFirstLetter(element?.brand?.name) || 'Brand Name' }}
                </div>
                <a
                  :href="`/product/${element.slug}?${utm.name}_source=${utm.source}&${utm.name}_medium=${utm.medium}&item_code=${element.item_code}`"
                  target="_blank"
                >
                  <div class="product-name">{{ element?.name }}</div>
                </a>
                <div class="seller-info">
                  Sold By:
                  {{ element?.attributes?.['marketer-name'] || 'Seller Name' }}
                </div>

                <div class="add-to-cart-container">
                  <div class="size-button-container">
                    <CustomDropdownCrossSell
                      v-if="availableSize?.length > 0"
                      v-model="selectedSize"
                      placeholder="Size : "
                      :items="availableSize"
                    />
                    <CustomDropdownCrossSell
                      v-model="selectedQuantity"
                      placeholder="Qty : "
                      :items="availableQuantity"
                    />
                  </div>
                  <div class="cart-button-container">
                    <div v-if="config.wishlist.active">
                      <div
                        @click="wishit($event, element, productData, accountsData)"
                        class="wish-list-icon-container"
                        v-if="isEventWrapper ? isWishlisted : follow[element?.slug]"
                      >
                        <WishListHeartIcon :selectedColor="config.wishlist.color" />
                      </div>
                      <div
                        @click="wishit($event, element, productData, accountsData)"
                        class="wish-list-icon-container"
                        v-else
                      >
                        <WishListHeartIcon :selectedColor="config.wishlist.defaultColor" />
                      </div>
                    </div>
                    <fdk-cart
                      v-slot="cartData"
                      ref="cart"
                      v-if="functionalConfigAddToCart?.add_to_cart?.active"
                    >
                      <button
                        v-if="functionalConfigAddToCart?.add_to_cart?.active"
                        theme="secondary"
                        class="add-to-cart-button"
                        size="small"
                        @click="addItemToCart({ element, cartData, productData, accountsData })"
                      >
                        {{
                          customText
                            ? customText
                            : functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart'
                        }}
                      </button>
                    </fdk-cart>
                  </div>
                </div>
                <div class="price-container" v-if="config?.price?.layout === 'layout1'">
                  <div class="price-sub-container" v-show="config?.price?.active">
                    <div
                      class="discounted-price"
                      v-show="
                        element?.discount &&
                        element?.discount !== '' &&
                        config.price.secondary_price_active
                      "
                    >
                      {{
                        `${element?.price?.marked?.currency_symbol}${element?.price?.marked?.max}`
                      }}
                    </div>
                    <div class="actual-price">
                      {{
                        `${element?.price?.effective?.currency_symbol}${element?.price?.effective?.max}`
                      }}
                    </div>
                  </div>
                  <div
                    v-show="
                      config?.discount?.active && element?.discount && element?.discount !== ''
                    "
                    class="offer-applied-container"
                  >
                    <div class="offer-applied-text">
                      {{ element?.discount }}
                    </div>
                    <ExclamationMarkIconSvg
                      alt="exclamation-mark-icon"
                      class="exclamation-mark-icon"
                      :color="config.discount.text_color"
                    />
                  </div>
                </div>
                <div class="price-container" v-if="config.price.layout === 'layout2'">
                  <div class="price-sub-container" v-show="config?.price?.active">
                    <div class="actual-price">
                      {{
                        `${element?.price?.effective?.currency_symbol}${element?.price?.effective?.max}`
                      }}
                    </div>
                    <div
                      class="discounted-price"
                      v-show="
                        element?.discount &&
                        element?.discount !== '' &&
                        config.price.secondary_price_active
                      "
                    >
                      {{
                        `${element?.price?.marked?.currency_symbol}${element?.price?.marked?.max}`
                      }}
                    </div>
                  </div>
                  <div
                    v-show="element?.discount && element?.discount !== ''"
                    class="offer-applied-container"
                  >
                    <div class="offer-applied-text">
                      {{ element?.discount }}
                    </div>
                    <img
                      alt="exclamation-mark-icon"
                      class="exclamation-mark-icon"
                      src="../../src/assets/images/ExclamationMarkIcon.png"
                    />
                  </div>
                </div>
                <div
                  class="expected-delivery-container"
                  v-show="config?.product_highlights?.active || failedAddingToCart"
                >
                  <div class="expected-delivery-title">
                    {{ failedAddingToCart ? failedAddingToCartMessage : 'Expected Delivery:' }}
                  </div>
                  <!-- <div class="expected-delivery-text">----</div> -->
                </div>
                <div class="add-to-cart-container-mobile">
                  <div class="size-button-container">
                    <CustomDropdownCrossSell
                      v-model="selectedSize"
                      placeholder="Size : "
                      :items="availableSize"
                      v-if="availableSize?.length > 0"
                    />
                    <CustomDropdownCrossSell
                      v-model="selectedQuantity"
                      placeholder="Qty : "
                      :items="availableQuantity"
                    />
                  </div>
                  <div class="cart-button-container">
                    <fdk-cart
                      v-slot="cartData"
                      ref="cart"
                      v-if="functionalConfigAddToCart?.add_to_cart?.active"
                    >
                      <button
                        v-if="functionalConfigAddToCart?.add_to_cart?.active"
                        class="add-to-cart-button"
                        theme="secondary"
                        size="small"
                        @click="addItemToCart({ element, cartData, productData, accountsData })"
                      >
                        {{
                          customText
                            ? customText
                            : functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart'
                        }}
                      </button>
                    </fdk-cart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fdk-accounts>
      </fdk-product-card>
    </div>
  </div>
</template>

<script>
import WishListHeartIcon from './WishListHeartIcon.vue';
import CustomDropdownCrossSell from './CustomDropdownCrossSell.vue';
import ExclamationMarkIconSvg from '../../src/components/ExclamationMarkIconSvg.vue';
export default {
  name: 'CartItemNew',
  props: {
    elementProps: Object,
    indexProps: Number,
    config: Object,
    functionalConfigAddToCart: Object,
    followProps: Object,
    isEventWrapper: Boolean,
    sizesWithQuantity: Object,
    utm: Object
  },
  components: {
    WishListHeartIcon,
    ExclamationMarkIconSvg,
    CustomDropdownCrossSell
  },
  data() {
    return {
      element: {},
      index: 0,
      follow: {},
      availableSize: [],
      availableQuantity: [],
      selectedSize: [],
      selectedQuantity: [],
      isWishlisted: false,
      customText: '',
      itemAddedToCart: false,
      failedAddingToCart: false,
      failedAddingToCartMessage: '',
      isPreviewScreen: false
    };
  },
  methods: {
    isPreviewScreenConfig() {
      if (window?.parent?.location?.pathname?.includes('cross-sell/preview')) {
        this.isPreviewScreen = true;
      } else {
        this.isPreviewScreen = false;
      }
    },
    capitalizeFirstLetter(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    testFunction() {
      this.$emit('testFunction');
    },
    removeItem(index) {
      this.$emit('removeItem', index);
    },
    wishit(event, element, productData, accountsData) {
      if (this.isEventWrapper) {
        this.isWishlisted = !this.isWishlisted;
      } else {
        this.$emit('wishit', {
          event,
          element,
          productData,
          accountsData
        });
      }
    },
    async addItemToCart(Obj) {
      if (this.isEventWrapper) {
        this.itemAddedToCart = true;
        setTimeout(() => {
          this.itemAddedToCart = false;
        }, 3000);
        return;
      }
      let citem = Obj?.element;
      let cartData = Obj?.cartData;

      try {
        const addProductToCart = await cartData?.addToCart({
          items: [
            {
              item_id: citem?.uid,
              item_size: this.selectedSize,
              quantity: parseInt(this.selectedQuantity)

              // For Testing Alphakult Fynd UAT
              // item_id: 7860910,
              // item_size: 'OS',
              // quantity: 1
            }
          ],
          buy_now: false
        });
        console.log('AddProductToCart -> ', addProductToCart);
        if (addProductToCart?.success) {
          console.log('Hel => ');
          this.itemAddedToCart = true;
        } else {
          this.failedAddingToCart = true;
          this.failedAddingToCartMessage = addProductToCart?.message;
        }

        setTimeout(() => {
          this.itemAddedToCart = false;
          this.failedAddingToCart = false;
          this.failedAddingToCartMessage = '';
        }, 3000);

        this.showLoader = false;
      } catch (error) {
        console.error('Failed to add product to cart ::: ', error);
        this.showLoader = false;
      }
    }
  },
  mounted() {
    this.element = this.elementProps;
    this.index = this.indexProps;
    this.follow = this.followProps;
    this.isPreviewScreenConfig();
    for (let i = 0; i < this.sizesWithQuantity?.length; i++) {
      this.availableSize = this.sizesWithQuantity?.map((ele, index) => {
        return {
          id: index,
          text: ele?.display,
          value: ele?.value
        };
      });
    }
    for (let i = 1; i <= this.sizesWithQuantity?.[0]?.quantity; i++) {
      this.availableQuantity?.push({
        id: i,
        text: i,
        value: i
      });
    }
    this.selectedSize = this.availableSize?.[0]?.value;
    this.selectedQuantity = this.availableQuantity?.[0]?.value;
  },
  watch: {
    itemAddedToCart(newVal) {
      console.log('NewVal => ', newVal);
      if (newVal === true) {
        this.customText = 'Added';
      } else {
        this.customText = '';
      }
    },
    selectedSize(newVal) {
      this.selectedQuantity = this.availableQuantity?.[0]?.value;
      for (let i = 0; i < this.sizesWithQuantity?.length; i++) {
        if (this.sizesWithQuantity?.[i]?.value === newVal) {
          this.availableQuantity = [];
          for (let j = 1; j <= this.sizesWithQuantity?.[i]?.quantity; j++) {
            this.availableQuantity?.push({
              id: j,
              text: j,
              value: j
            });
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;1,300&family=Red+Hat+Text:wght@400;500&display=swap');
@import '../less/CartItem.less';
</style>
