<template>
  <div
    class="offer-horizontal"
    v-if="discount != ''"
    :class="['offer-' + discountPosition + '-horizontal']"
  >
    <svg
      width="100%"
      height="100%"
      top="0"
      left="0"
      viewBox="0 0 36 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="50"
        width="50"
        height="36"
        rx="4"
        transform="rotate(-90 0 50)"
        fill="#ffffff00"
        
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.35036e-07 49.9525L17.1001 40L0 38L4.35036e-07 49.9525ZM35.9183 50L36 50L36 38L17.1001 40L35.9183 50Z"
        fill="#2F80ED"
        class="background_c"
      />
      <rect
        width="40"
        height="36"
        class="background_c"
        transform="translate(36) rotate(90)"
        fill="#2F80ED"
      />
    </svg>

    <div class="discount-text">
      <div>{{ discount.split("%")[0] }}%</div>
      <div>{{ discount.split("%")[1] }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LabelInvertedHorizontal",
  props: {
    discountPosition: {
      type: String,
      default: "",
    },
    discount: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.discount-text {
  font-size: calc(0px + min(23px, var(--discountSize)));
  font-weight: 500;
  color: var(--discountColor);
  top: 20px;
  margin-top: -77px;
  height: 58px;
  width: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.offer-horizontal {
  height: 74px;
  width: 52px;
  position: absolute;
  overflow: hidden;
}

.offer-top-left-inverted-horizontal {
  top: -1px;
  left: calc(4px + max(4px, var(--cardBorderRadius)));
}

.offer-top-right-inverted-horizontal {
  top: -1px;
  right: calc(4px + max(4px, var(--cardBorderRadius)));
}

.background_c {
  fill: var(--discountbgColor);
}
</style>
