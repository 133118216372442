<template>
  <div :style="cssProps" v-if="Object.keys(config).length && product.items.length">
    <div v-if="product?.items?.length <= 0"></div>
    <div v-else style="margin-top: 2rem">
      <IconAndTitle
        :iconUrl="config.widget_settings.logo ? config.widget_settings.logo : ''"
        :title="config.widget_settings.title"
        :subTitle="config.widget_settings.sub_title"
      />
      <div v-if="showLoader">
        <!-- Your loader component or HTML goes here -->
        <CustomLoader />
        <!-- Assuming you have a loader component named LoaderComponent -->
      </div>
      <div v-else>
        <div
          v-for="(element, index) in product?.items?.slice(0, config.widget_settings.max_products)"
          :key="index"
        >
          <CartItem
            :config="config"
            :functionalConfigAddToCart="functionalConfigAddToCart"
            :elementProps="element"
            :indexProps="index"
            :followProps="follow"
            :availableSizeProps="availableSize"
            :availableQuantityProps="availableQuantity"
            :selectedSizeProps="selectedSize"
            :selectedQuantityProps="selectedQuantity"
            :isEventWrapper="isEventWrapper"
            :sizesWithQuantity="element?.sizesWithQuantity"
            @testFunction="testFunction"
            @wishit="wishit"
            :utm = "utm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import { Utility } from '@gofynd/fdk-client-javascript/common';
import CartItem from './CartItem.vue';
import CustomLoader from '../../src/components/CustomLoader.vue';
import IconAndTitle from '../../src/components/IconAndTitle.vue';
export default {
  name: 'UpSellProducts',
  components: {
    IconAndTitle,
    CartItem,
    CustomLoader
  },
  data() {
    return {
      // config: Object,
      product: { items: [] },
      isMounted: false,
      follow: {},
      selectedQuantity: [],
      availableQuantity: [],
      selectedSize: [],
      availableSize: [],
      isWishListed: false,
      // pageDetail: undefined,
      showLoader: false,
      itemAddedToCart: false,
      utm: {
        source: 'recommendation',
        medium: 'cross-sell',
        name: 'utm'
      }
    };
  },
  async mounted() {
    // let url = window.location.href;
    // this.pageDetail = Utility.convertUrlToAction(url);
    // console.log('PageDetails => ', this.pageDetail);
    // console.log()
    // this.getData(this.recomm_slug, this.pageDetail);
    console.log('Config Cross Sell => ', this.config);
    this.isMounted = true;
    window?.FPI?.event.on('wishlist.add', this.wishAdd);
    window?.FPI?.event.on('wishlist.remove', this.wishRemove);
    // console.log('Recomm Slug 2 => ', this.recomm_slug);
    if (!this.isEventWrapper) {
      await this.getProduct(this.recomm_slug, this.pageDetail);
    } else {
      await this.getPreviewProducts();
    }
    // console.log('Data 2 => ', this.product);
  },
  methods: {
    testFunction() {},
    onWishList() {
      this.isWishListed = !this.isWishListed;
    },
    // getData(recomm_slug, pageDetail) {
    //   let url = window.location.origin;
    //   axios
    //     .get(
    //       `${url}/ext/recalpha/api/platform/v1.0/recommendations/${recomm_slug}/pages/${pageDetail.page.type}/config?only-active=true`
    //     )
    //     .then((response) => {
    //       this.config = response.data;
    //       this.getProduct(recomm_slug, pageDetail);
    //     })
    //     .catch((error) => {
    //       console.error('Failed to Fetch Config ::: ', error);
    //     });
    // },
    getProduct(recomm_slug, pageDetail) {
      const url = window.location.origin;
      const apiUrl = `${url}/ext/recalpha/api/application/v1.0/recommend/${recomm_slug}`;
      let params = {};
      let cartItemList = [];
      if (pageDetail.page.params && pageDetail.page.params.slug) {
        params.item_slug = pageDetail.page.params.slug[0];
      }
      if (pageDetail?.page?.type === 'cart') {
        const cartItem = window?.FPI?.state?.cart?._data?.all_cart_data?.items;
        params.item_slug = cartItem?.[0]?.product?.slug;
        for (let i = 0; i < cartItem?.length; i++) {
          cartItemList.push(cartItem?.[i]?.product?.slug);
        }
      }

      axios
        .post(apiUrl, {
          item_slug: cartItemList
        })
        .then((response) => {
          console.log('Response => ', response);
          this.product = response.data;
          this.processProducts();
        })
        .catch((error) => {
          console.error('Failed to Fetch Products ::: ', error);
        });
    },
    processProducts() {
      console.log('Products => ', this.product);
      try {
        this.brand = this.product?.items?.[0]?.brand?.name ?? 'brand';
        this.gender = this.product?.items?.[0]?.attributes?.gender ?? 'gender';
        this.category = this.product?.items?.[0]?.category?.[0]?.name ?? 'category';
        let f = {};
        let id_to_slug = {}; // needed bcoz FPI wishlist event uses slug where as  state.products.wishlistIds.subscribe use ids
        let keymap = this.config.reviews.keymap; //review keymap
        let r = {};
        let availableSize = this.availableSize;
        let selectedSize = this.selectedSize;
        let availableQuantity = this.availableQuantity;
        let selectedQuantity = this.selectedQuantity;
        this.product.items.forEach(function (item) {
          //wishlist map
          f[item.slug] = false;
          id_to_slug[item.uid] = item.slug; //using id helps with FPI events

          //review data map
          let rmap = {
            ratings_total: 0,
            ratings_count: 0,
            reviews_count: 0
          };
          item._custom_meta.forEach(function (meta_item) {
            if (keymap.ratings_total == meta_item.key) {
              rmap.ratings_total = parseInt(meta_item.value);
            } else if (keymap.ratings_count == meta_item.key) {
              rmap.ratings_count = parseInt(meta_item.value);
            } else if (keymap.reviews_count == meta_item.key) {
              rmap.reviews_count = parseInt(meta_item.value);
            }
          });

          rmap.rating = parseInt(rmap.ratings_total / rmap.ratings_count);
          r[item.slug] = rmap;
          if (item?.sizes?.length > 0) {
            availableSize.push(item?.sizes);
            selectedSize.push(item?.sizes?.[0]);
          }

          if (item?.net_quantity?.max > 0) {
            let x = [];
            for (let i = 1; i <= item?.net_quantity?.max; i++) {
              x.push(i);
            }
            availableQuantity.push(x);
            selectedQuantity.push(x?.[0]);
          } else {
            let x = [];
            x.push(1);
            availableQuantity.push(x);
            selectedQuantity.push(x?.[0]);
          }
        });
        this.availableSize = availableSize;
        this.selectedSize = selectedSize;
        this.selectedQuantity = selectedQuantity;
        this.availableQuantity = availableQuantity;
        if (this.availableSize && this.availableSize?.length > 0) {
          for (let i = 0; i < this.availableSize?.length; i++) {
            this.availableSize[i] = this.availableSize[i].map((element, index) => {
              return { id: index, text: element, value: element };
            });
          }
        }

        if (this.availableQuantity && this.availableQuantity?.length > 0) {
          for (let i = 0; i < this.availableQuantity?.length; i++) {
            this.availableQuantity[i] = this.availableQuantity[i].map((element, index) => {
              return { id: index, text: element, value: element };
            });
          }
        }

        //Adds already wishlisted items to map
        window?.FPI?.state?.products?.wishlistIds?.subscribe((items) => {
          items.forEach((item) => {
            if (id_to_slug[item] !== undefined) {
              f[id_to_slug[item]] = true;
            }
          });
        });
        this.follow = f;
        this.review_map = r;
      } catch (error) {
        console.error('Failed in fetching products => ', error);
      }
    },
    // async addItemToCart(Obj) {
    //   // let event = Obj?.event;
    //   let citem = Obj?.element;
    //   let cartData = Obj?.cartData;
    //   // let productData = Obj?.productData;
    //   let accountsData = Obj?.accountsData;
    //   let elementIndex = Obj?.index;
    //   console.log(citem, elementIndex);
    //   // this.showLoader = true;
    //   if (accountsData.is_logged_in) {
    //     // Toggle the buttonClicked property to apply the effect

    //     try {
    //       // const fetchCartData = await cartData?.getCart();
    //       const addProductToCart = await cartData?.addToCart({
    //         items: [
    //           {
    //             // item_id: citem?.uid,
    //             // item_size: this.selectedSize?.[elementIndex],
    //             // quantity: parseInt(this.selectedQuantity?.[elementIndex]),

    //             item_id: 7860910,
    //             item_size: 'OS',
    //             quantity: 1
    //           }
    //         ],
    //         buy_now: false
    //       });
    //       console.log('AddProductToCart -> ', addProductToCart);
    //       if (addProductToCart?.success) {
    //         console.log("Hel => ")
    //         this.itemAddedToCart = true;
    //       }

    //       console.log("Hel 2 => ", this.itemAddedToCart)

    //       setTimeout(() => {
    //         this.itemAddedToCart = false;
    //       }, 3000);

    //       this.showLoader = false;
    //       // this.selectedQuantity = [];
    //       // this.availableQuantity = [];
    //       // this.selectedSize = [];
    //       // this.availableSize = [];
    //       // this.getData(this.recomm_slug, this.pageDetail);
    //     } catch (error) {
    //       // console.error('Failed to add product to cart ::: ', JSON.stringify(error));
    //       console.error('Failed to add product to cart ::: ', error);
    //       this.showLoader = false;
    //       // this.selectedQuantity = [];
    //       // this.availableQuantity = [];
    //       // this.selectedSize = [];
    //       // this.availableSize = [];
    //     }
    //   } else {
    //     this.showLoader = false;
    //     accountsData.openLogin();
    //   }
    // },
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    wishit(Obj) {
      let event = Obj?.event;
      let citem = Obj?.element;
      let productData = Obj?.productData;
      let accountsData = Obj?.accountsData;
      if (accountsData.is_logged_in) {
        citem.follow = this.follow[citem.slug];
        productData.updateWishList(event, citem);
        this.onWishList();
      } else {
        accountsData.openLogin();
      }
    },
    wishAdd(data) {
      this.follow[data.slug] = true;
    },
    wishRemove(data) {
      this.follow[data.slug] = false;
    },
    getPreviewProducts() {
      axios.interceptors.request.use((config) => {
        config.headers['x-company-id'] = window.parent?.location?.href?.split?.('/')?.[5];
        return config;
      });
      axios
        .get(
          `https://${window.location.host}/api/platform/v1.0/recommendations-new/product-preview?application_id=${this.applicationId}&company_id=${this.companyId}`
        )
        .then((res) => {
          console.log('Response 2 => ', res);
          this.product = res?.data;
          this.processProducts();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  props: {
    id: String,
    recomm_slug: String,
    config: Object,
    functionalConfigAddToCart: Object,
    pageDetail: String,
    isEventWrapper: Boolean,
    companyId: Number,
    applicationId: Number,
    configId: String
  },
  computed: {
    cssProps() {
      return {
        '--wishIconColor': this.config.wishlist.defaultColor,
        '--discountColor': this.config.discount.text_color,
        '--imageHeight': this.config?.product_image?.height + 'px',
        '--discountSize': this.config.discount.text_size + 'px',
        '--discountbgColor': this.config.discount.background_color,
        '--namebrandColor': this.config.product_title.text_color,
        '--nameproductColor': this.config.product_title.secondary_text_color,
        '--namebrandSize': this.config.product_title.font_size + 'px',
        '--nameWidth': this.config.product_title.text_limit + 'ch',
        '--brandFontWeight': this.config.product_title.highlight_brand_name ? 600 : 400,
        '--breakWhiteSpace': this.config.product_title.multiline_product_name
          ? 'nowrap'
          : 'break-spaces',
        '--overflowVisible': this.config.product_title.multiline_product_name
          ? 'hidden'
          : 'visible',
        '--primaryPriceColor': this.config.price.text_color,
        '--secondaryPriceColor': this.config.price.secondary_text_color,
        '--pricefontSize': this.config.price.text_size + 'px',
        '--priceFontWeight': this.config.price.highlight_price ? 600 : 400,
        '--addtocartFont': this.config.cart_button.font_size + 'px',
        '--addtocartlabelColor': this.config.cart_button.label_color,
        '--addtocartbgColor': this.config.cart_button.background_color,
        '--titleColor': this.config.widget_settings.title_color,
        '--subtitleColor': this.config.widget_settings.subtitle_color,
        '--cardBorderRadius': this.config.product_card_style.border_radius + 'px',
        '--cardBackgroundColor': this.config.product_card_style.background_color,
        '--cardBorderColor': this.config.product_card_style.border_color,
        '--cardPadding': this.config.product_card_style.padding + 'px',
        '--cardMargin': this.config.product_card_style.margin + 'px',
        '--highlightfontSize': this.config.product_highlights.font_size + 'px',
        '--highlightMarginTop': this.config.product_highlights.margin_top + 'px',
        '--highlightBorderBottom': this.config.product_highlights.border_bottom ? '1px' : '0px',
        '--highlightColor': this.config.product_highlights.font_color,
        '--cardBoxShadow': this.config.product_card_style.box_shadow
          ? 'rgba(99, 99, 99, 0.5) 0px 2px 8px 0px'
          : 'none',
        '--leftArrowVisibility':
          this.config.widget_settings.max_products > 2 ? 'inherit' : 'hidden',
        '--rightArrowVisibility':
          this.config.widget_settings.max_products > 2 ? 'inherit' : 'hidden',
        '--titleAlignment': this.config.new_config.widget_settings.title_alignment
          ? this.config.new_config.widget_settings.title_alignment
          : 'left',
        '--subtitleAlignment': this.config.new_config.widget_settings.sub_title_alignment
          ? this.config.new_config.widget_settings.sub_title_alignment
          : 'left',
        '--titleFontSize': this.config.new_config.widget_settings.title_font_settings.font_size
          ? `${this.config.new_config.widget_settings.title_font_settings.font_size}px`
          : `12px`,
        '--subTitleBottomSpacing': this.config.new_config.widget_settings.sub_title_bottom_spacing
          ? `${this.config.new_config.widget_settings.sub_title_bottom_spacing}px`
          : 0,
        '--subtitleFontSize': this.config.new_config.widget_settings.sub_title_font_settings
          .font_size
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_size}px`
          : `12px`,
        '--titleFontWeight': this.config.new_config.widget_settings.title_font_settings.font_weight
          ? `${this.config.new_config.widget_settings.title_font_settings.font_weight}`
          : 600,
        '--subtitleFontWeight': this.config.new_config.widget_settings.sub_title_font_settings
          .font_weight
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_weight}`
          : 500,
        '--reviewFontSize': this.config.new_config.reviews.review_font_size
          ? `${this.config.new_config.reviews.review_font_size}px`
          : `12px`,
        '--reviewFontColor': this.config.new_config.reviews.review_font_colour,

        // Global Font Family
        '--globalFontFamily': this.isEventWrapper ? 'Inter' : 'inherit',

        // Add to cart button functional config
        '--addToCartBtnBorderRadius': `${this.functionalConfigAddToCart?.add_to_cart?.border_radius ? this.functionalConfigAddToCart?.add_to_cart?.border_radius : 4}px`,
        '--addToCartBtnHeight': `${this.functionalConfigAddToCart?.add_to_cart?.button_size ? this.functionalConfigAddToCart?.add_to_cart?.button_size : 40}px`,
        '--addToCartBtnWidth': `${this.functionalConfigAddToCart?.add_to_cart?.button_width ? this.functionalConfigAddToCart?.add_to_cart?.button_width : 40}px`,
        '--addToCartBtnFontSize': `${this.functionalConfigAddToCart?.add_to_cart?.font_size ? this.functionalConfigAddToCart?.add_to_cart?.font_size : 14}px`,
        '--addToCartBtnTextAlignment': `${this.functionalConfigAddToCart?.add_to_cart?.text_alignment ? this.functionalConfigAddToCart?.add_to_cart?.text_alignment : 'center'}`,
        '--addToCartBtnBgColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color : '#ffffff'}`,
        '--addToCartBtnHoverBgColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color_on_hover ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color_on_hover : '#ffffff'}`,
        '--addToCartBtnBorderColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_border_color ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_border_color : 'black'}`,
        '--addToCartBtnLabelColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_label_color ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_label_color : 'white'}`
      };
    }
  }
};
</script>

<style lang="less" scoped>
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;1,300&family=Red+Hat+Text:wght@400;500&display=swap');
@import '../less/CartItem.less';
</style>
