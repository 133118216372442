<template>
  <div v-if="isEventWrapper" class="button-wrapper">
    <button
      theme="secondary"
      class="add-to-cart-button"
      size="small"
      @click="
        addItemToCart($event, {
          element,
          cartData,
          productData,
          accountsData
        })
      "
    >
      {{
        customText
          ? customText
          : functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart'
      }}
    </button>
  </div>
  <div v-else>
    <fdk-cart v-slot="cartData" ref="cart">
      <div class="button-wrapper">
        <button
          theme="secondary"
          class="add-to-cart-button"
          size="small"
          @click="
            addItemToCart($event, {
              element,
              cartData,
              productData,
              accountsData
            })
          "
        >
          {{
            customText
              ? customText
              : functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart'
          }}
        </button>
      </div>
    </fdk-cart>
  </div>
</template>

<script>
export default {
  name: 'AddToCartButton',
  props: {
    accountsData: Object,
    productData: Object,
    element: Object,
    functionalConfigAddToCart: Object,
    isEventWrapper: Boolean,
    addedToCart: Boolean,
    failedAddingToCart: Boolean
  },
  data() {
    return {
      customText: ''
    };
  },
  mounted() {},
  methods: {
    addItemToCart(event, Obj) {
      if (this.isEventWrapper) {
        this.customText = this.functionalConfigAddToCart?.add_to_cart?.added_to_cart_button_label;
        setTimeout(() => {
          this.customText = '';
          this.$emit('addItemToCart', {
            event: 'addedToCart'
          });
        }, 3000);
      } else {
        // this.customText = 'Adding to Cart...'; // Need to keep this Dynamic
        this.$emit('addItemToCart', {
          event,
          element: Obj.element,
          cartData: Obj.cartData,
          productData: Obj.productData,
          accountsData: Obj.accountsData
        });
      }
    }
  },
  watch: {
    addedToCart(newVal) {
      if (newVal === true) {
        this.customText = this.functionalConfigAddToCart?.add_to_cart?.added_to_cart_button_label;
      } else {
        this.customText = '';
      }
    },
    failedAddingToCart(newVal) {
      if (newVal === true) {
        this.customText = 'Failed';
      } else {
        this.customText = '';
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import '../less/appbindings.less';
</style>
