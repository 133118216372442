import { render, staticRenderFns } from "./ImageAndOffer.vue?vue&type=template&id=296cfb66&scoped=true"
import script from "./ImageAndOffer.vue?vue&type=script&lang=js"
export * from "./ImageAndOffer.vue?vue&type=script&lang=js"
import style0 from "./ImageAndOffer.vue?vue&type=style&index=0&id=296cfb66&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296cfb66",
  null
  
)

export default component.exports