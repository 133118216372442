<template>
  <div
    v-if="isHighlightsActive && highlights && highlights.length"
    class="highlights"
  >
    <span
      class="highlights-row"
      v-for="(highlight, index) in highlights.slice(0, highlightsCount)" 
      :key="index"
      >{{ highlight }}</span
    >
  </div>
</template>
<script>
export default {
  name: "HighlightsBox",
  props: {
    highlights: {
      type: Array,
      default: null,
    },
    isHighlightsActive: {
      type: Boolean,
      default: false,
    },
    highlightsCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.highlights{
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.highlights-row {
  display: block;
  font-weight: 400;
  font-size: var(--highlightfontSize);
  color: var(--highlightColor);
  margin-top: var(--highlightMarginTop);
  border-bottom: var(--highlightBorderBottom) solid #e0e0e0;
      text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-height: 17px;

}
</style>