<template>
  <div v-if="discount != ''" class="offer-label-horizontal" :class="['offer-' + discountPosition]">
    <div class="svg-holder">
      <svg
        width="100%"
        height="100%"
        top="0"
        left="0"
        viewBox="0 0 56 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="20" rx="4" fill="#ffffff00" />
        <rect width="46.1" height="20" fill="#2F80ED" class="background_c" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M55.9524 0L46 9.5V0H55.9524ZM56 19.9545V20H46V9.5L56 19.9545Z"
          fill="#2F80ED"
          class="background_c"
        />
      </svg>
    </div>
    <div class="offer-text-container">
      <div class="offer-text">{{ discount }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LabelHorizontal",
  props: {
    discountPosition: {
      type: String,
      default: "",
    },
    discount: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.offer-label-horizontal {
  height: 30px;
  width: 90px;
  position: absolute;
  overflow: hidden;
}

.offer-top-left {
  top: calc(4px + max(4px, var(--cardBorderRadius)));
  left: 0px;
}
.offer-top-right {
  top: calc(4px + max(4px, var(--cardBorderRadius)));
  right: 0px;
}
.offer-top-right div {
  transform: scaleX(-1);
}

.offer-text {
  font-size: calc(0px + min(16px, var(--discountSize)));
  font-weight: 500;
  color: var(--discountColor);
  overflow: hidden;
  display: flex;
  height: 30px;
  width: 77px;
  justify-content: center;
  align-items: center;
}

.offer-bottom-left {
  left: 0px;
  bottom: calc(2px + max(6px, var(--cardBorderRadius)));
}

.background_c {
  fill: var(--discountbgColor);
}

.offer-text-container {
  margin-top: -36px;
}
</style>
