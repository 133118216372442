<template>
  <div>
    <ApiWrapper id="cross-sell" />
  </div>
</template>

<script>
import ApiWrapper from './hoc/ApiWrapper.vue';
export default {
  name: 'by-cross-sell',
  components: {
    ApiWrapper
  }
};
</script>
