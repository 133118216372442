<template>
  <div ref="dropdown">
    <div class="dropdown">
      <button class="dropdown-toggle" @click="toggleDropdown">
        <p class="dropdown-text">
          {{
            selectedValueName
              ? placeholder + ' ' + selectedValueName
              : placeholder + ' ' + items?.[0]?.text
          }}
        </p>
        <span class="dropdown-icon" :class="{ open: isOpen }"></span>
      </button>
      <ul v-if="isOpen" class="dropdown-menu" @click.stop>
        <li v-for="(item, index) in items" :key="index" @click="selectOption(item)">
          <img :src="item?.logo" class="logo-image" v-if="item?.logo" />
          {{ item?.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',
  data() {
    return {
      isOpen: false,
      internalValue: this.value,
      selectedValueName: '',
      options: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 4', 'Option 4']
    };
  },
  props: {
    value: String,
    placeholder: String,
    label: String,
    items: Object
  },
  mounted() {
    // Add the click event listener to the document
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleClickOutside(event) {
      // Check if the click is outside the dropdown
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
    selectOption(option) {
      this.internalValue = option?.value;
      this.selectedValueName = option?.text;
      this.$emit('input', option?.value);
      this.isOpen = false;
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
      },
      selectedValueName(newVal) {
        console.log('newVal => ', newVal);
      }
    }
  }
};
</script>

<style scoped>
ul {
  padding-inline-start: 0;
}

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 6rem;
}

.dropdown-toggle {
  position: relative;
  padding-left: 8px;
  color: black;
  cursor: pointer;
  border-radius: 1.5rem;
  width: 100%;
  height: 2rem;
  background: var(--alabaster, #f8fcfe);
  border: 1px solid var(--porcelain, #e9f1f8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  margin: 0;
  color: #365660;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
}

.dropdown-toggle:hover {
  /* background-color: #2980b9; */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 12rem;
  overflow-y: scroll;
}

.label-dropdown {
  margin-bottom: 4px;
}

.dropdown-menu li {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  min-height: 2.8rem;
  display: flex;
  align-items: center;
  white-space: normal;
}

.dropdown-menu li:not(:last-child) {
  border-bottom: 0.4px solid black;
}

.logo-image {
  height: 80px;
  width: auto;
  padding-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.dropdown-icon {
  margin-left: 10px;
  border: solid #365660;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.2s;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.dropdown-icon.open {
  transform: rotate(-135deg);
}
</style>
