<template>
  <div>
    <ApiWrapper id="price" />
  </div>
</template>

<script>
import ApiWrapper from "./hoc/ApiWrapper.vue";
export default {
  name: "by-brand",
  components: {
    ApiWrapper,
  },
};
</script>
